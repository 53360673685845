// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-sections-closer-look-js": () => import("./../../../src/pages/about/sections/closerLook.js" /* webpackChunkName: "component---src-pages-about-sections-closer-look-js" */),
  "component---src-pages-about-sections-intro-js": () => import("./../../../src/pages/about/sections/intro.js" /* webpackChunkName: "component---src-pages-about-sections-intro-js" */),
  "component---src-pages-about-sections-qualifications-carousels-basics-carousel-js": () => import("./../../../src/pages/about/sections/qualifications/carousels/basicsCarousel.js" /* webpackChunkName: "component---src-pages-about-sections-qualifications-carousels-basics-carousel-js" */),
  "component---src-pages-about-sections-qualifications-carousels-libs-frameworks-carousel-js": () => import("./../../../src/pages/about/sections/qualifications/carousels/libsFrameworksCarousel.js" /* webpackChunkName: "component---src-pages-about-sections-qualifications-carousels-libs-frameworks-carousel-js" */),
  "component---src-pages-about-sections-qualifications-carousels-miscellaneous-carousel-js": () => import("./../../../src/pages/about/sections/qualifications/carousels/miscellaneousCarousel.js" /* webpackChunkName: "component---src-pages-about-sections-qualifications-carousels-miscellaneous-carousel-js" */),
  "component---src-pages-about-sections-qualifications-qualifications-js": () => import("./../../../src/pages/about/sections/qualifications/qualifications.js" /* webpackChunkName: "component---src-pages-about-sections-qualifications-qualifications-js" */),
  "component---src-pages-about-sections-qualities-js": () => import("./../../../src/pages/about/sections/qualities.js" /* webpackChunkName: "component---src-pages-about-sections-qualities-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-fernweh-js": () => import("./../../../src/pages/projects/fernweh.js" /* webpackChunkName: "component---src-pages-projects-fernweh-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-rosa-js": () => import("./../../../src/pages/projects/rosa.js" /* webpackChunkName: "component---src-pages-projects-rosa-js" */),
  "component---src-pages-projects-wotsch-js": () => import("./../../../src/pages/projects/wotsch.js" /* webpackChunkName: "component---src-pages-projects-wotsch-js" */)
}

